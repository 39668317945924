<template>
  <div>
     <b-overlay :show="isBusy">
    <stats-card
      :title="title"
      :type="type"
      :sub-title="count.toString()"
      :icon="icon"
    >
      <template slot="footer">
        <span v-if="lastMonthPercent >= 0" class="text-success mr-2"
          ><i class="fa fa-arrow-up"></i> {{ lastMonthPercent }}%</span
        >
        <span v-if="lastMonthPercent < 0" class="text-failure mr-2"
          ><i class="fa fa-arrow-down"></i> {{ lastMonthPercent }}%</span
        >
        <span class="text-nowrap">Since last month</span>
      </template>
    </stats-card>
     </b-overlay>
  </div>
</template>

<script>
export default {
  inject: ["userStore"],
  props: {
    isBusy: {
      type: Boolean,
      description: 'for overlay',
      default: false
    },
    icon:{
        type: String,
        description:"any fontawesome string, ie. fas fa-bed",
    },
    type: {
      type: String,
      description:
        "gradient-red, or radient-orange or gradient-green or gradient-info",
    },
    title: {
      type: String,
    },
    lastMonthPercent: {},
    count: {},
  },
  components: {},

  data() {
    return {};
  },

  created() {},
  mounted() {},

  methods: {},
};
</script>
<style></style>
