import axios from "axios"


export const getMiniCharts = (token) => {
  return axios.get(process.env.VUE_APP_API_URL + `charts/mini-charts`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}


export const getAnalytics = (token) => {
return axios.get(process.env.VUE_APP_API_URL + `charts/analytics`, {
  headers: {
    Authorization: 'Bearer ' + token,
  },
})
}