<template>
  <div>
     <b-overlay :show="isBusy">
      <card 
      type="default" header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h6 class="text-light text-uppercase ls-1 mb-1">{{overview}}</h6>
                <h5 class="h3 text-white mb-0">{{title}}</h5>
              </b-col>
              <b-col>
                <b-nav class="nav-pills justify-content-end">
                  <b-nav-item
                       class="mr-2 mr-md-0"
                       :active="config.activeIndex === 0"
                       link-classes="py-2 px-3"
                       @click.prevent="initBigChart(0)">
                      <span class="d-none d-md-block">{{label1}}</span>
                      <span class="d-md-none">Y</span>
                  </b-nav-item>
                  <b-nav-item
                    link-classes="py-2 px-3"
                    :active="config.activeIndex === 1"
                    @click.prevent="initBigChart(1)"
                  >
                    <span class="d-none d-md-block">{{label2}}</span>
                    <span class="d-md-none">LY</span>
                  </b-nav-item>
                </b-nav>
              </b-col>
            </b-row>
            <line-chart
               v-if="config"
              :height="350"
              ref="bigChart"
              :chart-data="config.chartData"
              :extra-options="config.extraOptions"
            >
            </line-chart>
          </card>
     </b-overlay>
  </div>
</template>

<script>
 import LineChart from '@/components/Charts/LineChart';

export default {
  inject: ["userStore"],
  props: {
    isBusy: {
      type: Boolean,
      description: 'for overlay',
      default: false
    },
    title: {
      type: String,
    },
    overview: {
      type: String,
      default: 'Overview'
    },
    config:{
      type: Object
    },
    label1:{
      type: [String, Number],
      description: 'the first chart button'
    },
   label2:{
      type: [String, Number],
     description: 'the first chart button'
    },
  },
  components: {LineChart},

  data() {
    return {};
  },

  created() {},
  mounted() {},

  methods: {
    initBigChart(index){
      this.$emit('init', index)
    }
    
  },
};
</script>
<style></style>
