<template>
  <div>
    <base-header class="pb-6" type="primary">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb
              :crumbs="generateBreadCrumb()"
            ></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
      <!-- Card stats -->
      <b-row>
        <b-col xl="3" md="6">
          <MiniChart
            title="Stays"
            icon="fas fa-bed"
            :isBusy="isMiniBusy"
            type="gradient-red"
            :count="staysResponse.count"
            :lastMonthPercent="staysResponse.lastMonthPercent"
          ></MiniChart>
        </b-col>
        <b-col xl="3" md="6">
          <MiniChart
            title="Guests"
            icon="fas fa-user"
            :isBusy="isMiniBusy"
            type="gradient-orange"
            :count="guestResponse.count"
            :lastMonthPercent="guestResponse.lastMonthPercent"
          ></MiniChart>
        </b-col>
        <b-col xl="3" md="6">
          <MiniChart
            title="Services"
            icon="fas fa-spa"
            :isBusy="isMiniBusy"
            type="gradient-green"
            :count="serviceResponse.count"
            :lastMonthPercent="serviceResponse.lastMonthPercent"
          ></MiniChart>
        </b-col>
        <b-col xl="3" md="6">
          <MiniChart
            title="Retreat Days"
            icon="fas fa-sun"
            :isBusy="isMiniBusy"
            type="gradient-info"
            :count="retreatsResponse.count"
            :lastMonthPercent="retreatsResponse.lastMonthPercent"
          ></MiniChart>
        </b-col>
      </b-row>
    </base-header>

    <!--Charts-->
    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="6">
          <LineMonthlyChart
            :label1="thisYear"
            :label2="lastYear"
            :isBusy="isBusy"
            title="Watched Videos"
            :config="bigLineChart"
            @init="initBigChart"
          >
          </LineMonthlyChart>
        </b-col>

        <b-col xl="6">
          <social-traffic-table
            :isBusy="isBusy"
            :tableData="tableData"
          ></social-traffic-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import BarChart from "@/components/Charts/BarChart";
import MiniChart from "./MiniChart.vue";

// Components
// import BaseProgress from '@/components/Base/BaseProgress';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import StatsCard from "@/components/Cards/StatsCard";

// Lists
//import ActivityFeed from './ActivityFeed';
//import TaskList from './TaskList';
//import UserList from './UserList';
//import ProgressTrackList from './ProgressTrackList';

// Tables
//import LightTable from './LightTable';
import SocialTrafficTable from "./SocialTrafficTable";
import PageVisitsTable from "./PageVisitsTable";
import * as ChartRepo from "@/api-repo/charts";
import LineMonthlyChart from "./LineMonthlyChart.vue";

export default {
  inject: ["userStore"],
  components: {
    //ActivityFeed,
    BarChart,
    //BaseProgress,
    RouteBreadCrumb,
    StatsCard,
    MiniChart,
    //TaskList,
    PageVisitsTable,
    SocialTrafficTable,
    LineMonthlyChart,
    //LightTable,
    //UserList,
    //ProgressTrackList
  },

  data() {
    return {
      thisYear: new Date().getFullYear(),
      lastYear: new Date().getFullYear() - 1,
      isMiniBusy: false,
      isBusy: false,
      guestResponse: { count: 0, lastMonthPercent: 0 },
      retreatsResponse: { count: 0, lastMonthPercent: 0 },
      serviceResponse: { count: 0, lastMonthPercent: 0 },
      staysResponse: { count: 0, lastMonthPercent: 0 },
      tableData: [
        {
          name: "Home",
          value: "home",
          visitors: "0",
          progress: 0,
          progressType: "gradient-danger",
        },
        {
          name: "Wellness Plan",
          value: "welnessplan",
          visitors: "0",
          progress: 0,
          progressType: "gradient-success",
        },
        {
          name: "Recipes",
          value: "recipes",
          visitors: "0",
          progress: 0,
          progressType: "gradient-primary",
        },
        {
          name: "Videos",
          visitors: "0",
          value: "videos",
          progress: 0,
          progressType: "gradient-info",
        },
        {
          name: "Contact Us",
          visitors: "0",
          value: "contactUs",
          progress: 0,
          progressType: "gradient-warning",
        },
      ],
      bigLineChart: {
        allData: [],
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: "Performance",
              data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
            },
          ],
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Sales",
              data: [25, 20, 30, 22, 17, 29],
            },
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
    };
  },

  created() {
    this.initSmallCharts();
  },
  mounted() {},

  methods: {
    generateBreadCrumb() {
      return [{ label: "Dashboard" }];
    },

    initSmallCharts() {
      this.isMiniBusy = true;
      ChartRepo.getMiniCharts(this.userStore.state.token).then((res) => {
        this.guestResponse = res.data.data.guestResponse;
        this.retreatsResponse = res.data.data.retreatsResponse;
        this.serviceResponse = res.data.data.serviceResponse;
        this.staysResponse = res.data.data.staysResponse;
        this.isMiniBusy = false;
      });
      this.isBusy = true;
      ChartRepo.getAnalytics(this.userStore.state.token).then((res) => {
        this.tableData.forEach((e) => {
          let found = res.data.data.pageStats[e.value];
          if (found) {
            e.visitors = found.toString();
            e.progress = (found / res.data.data.pageStats.total) * 100;
          }
        });
        let videosWatchedThisYear =
          res.data.data.videoStats.videosWatchedThisYear;
        let videosWatchedLastYear =
          res.data.data.videoStats.videosWatchedLastYear;
        this.bigLineChart.allData = [
          videosWatchedThisYear,
          videosWatchedLastYear,
        ];
        this.initBigChart(0);
        this.isBusy = false;
      });
    },

    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            label: "Performance",
            data: this.bigLineChart.allData[index],
          },
        ],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
  },
};
</script>
<style></style>
